// referenced from https://codepen.io/maxakohler/pen/nzvwBm

$bg: rgb(210, 210, 180);
$orange: #f8c475;
$red: #f87575;
$green: rgb(180, 210, 140);
$blue: #75bff8;
$sky: lighten($blue, 10);
$yellow: #f3f875;
$purple: #d9abff;
$pink: #ff89b3;
@mixin animation-duration($t) {
  -moz-animation-duration: $t;
  -webkit-animation-duration: $t;
  -moz-animation-duration: $t;
}
@mixin animation-delay($t) {
  -moz-animation-delay: $t;
  -webkit-animation-delay: $t;
  -moz-animation-delay: $t;
}
@mixin transform($property: none) {
  transform: $property;
  -moz-transform: $property;
  -webkit-transform: $property;
  -o-transform: $property;
}

@mixin animation($name, $duration, $delay: 0s, $fill-mode: forwards) {
  -moz-animation-name: $name;
  -webkit-animation-name: $name;

  -webkit-animation-fill-mode: $fill-mode;
  -moz-animation-fill-mode: $fill-mode;

  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;

  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(reveal) {
  to {
    opacity: 0.75;
  }
}

@include keyframes(turn-right) {
  from {
    @include transform(rotateY(-140deg));
  }
  to {
    @include transform(rotateY(0deg));
  }
}
@include keyframes(turn-left) {
  from {
    @include transform(rotateY(140deg));
  }
  to {
    @include transform(rotateY(0deg));
  }
}
@mixin config($w, $height, $background, $border-color, $border-width, $leaves) {
  height: $w;
  width: $w;
  opacity: 0;
  background-color: $background;
  border-radius: $w * 5;
  border: $border-width solid $border-color;
  box-shadow: 0 0 0 0.2em darken($border-color, 4), 0 0 0 0.2em lighten($background, 4) inset;
  bottom: $height;

  span {
    top: $w + $border-width + 2px;
    height: $height - 2px;

    left: calc($w / 2.3);
    z-index: -1;

    @if $leaves == 0 {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    @if $leaves == 1 {
      &:before {
        display: block;
      }
      &:after {
        display: none;
      }
    }
    @if $leaves == 2 {
      &:before {
        display: block;
      }
      &:after {
        display: block;
      }
    }
  }
}

.flower {
  opacity: 0;
  @include animation(reveal, 1s);
  position: absolute;
  z-index: 0;

  span {
    background: $green;
    width: 0.3em;
    z-index: 0;
    display: block;
    position: absolute;

    &:after {
      content: '';
      @include transform(rotateY(-140deg));
      -moz-transform-origin: left;
      -webkit-transform-origin: left;
      width: 1.2em;
      height: 1.2em;
      display: block;
      background: darken($green, 5);
      border-radius: 2em 0em 2em 0em;
      box-shadow: 0 0 0 0.2em darken($green, 8) inset;
      z-index: 0;
      left: 0.3em;
      position: absolute;
      top: 20px;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    &:before {
      @include transform(rotateY(140deg));
      -moz-transform-origin: right;
      -webkit-transform-origin: right;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      content: '';
      width: 1.2em;
      height: 1.2em;
      display: block;
      background: darken($green, 7);
      border-radius: 0em 2em 0em 2em;
      box-shadow: 0 0 0 0.2em darken($green, 8) inset;
      z-index: 0;
      position: absolute;
      left: -1.1em;
      top: 10px;
    }
  }
}

//Configuring the flowers:
//=========================

.type-0 {
  @include config(22px, 68px, $yellow, $orange, 8px, 1);
}
.type-1 {
  @include config(18px, 55px, $sky, $blue, 8px, 2);
}
.type-2 {
  @include config(32px, 65px, $orange, $red, 8px, 1);
}
.type-3 {
  @include config(25px, 50px, $red, $orange, 8px, 1);
}
.type-4 {
  @include config(40px, 55px, $purple, $pink, 8px, 2);
}
.type-5 {
  @include config(18px, 30px, $blue, $sky, 8px, 1);
}
.type-6 {
  @include config(38px, 40px, $red, $orange, 8px, 2);
}

@for $i from 0 through 30 {
  .flower-#{$i} {
    @include animation-delay(0.1s * $i);
    left: 1em + (7em * $i);
    span:before {
      @include animation(turn-right, 4s, 0.1s * $i);
    }
    span:after {
      @include animation(turn-left, 4s, 0.1s * $i);
    }
  }
}
